<template>
  <div class="print" style="overflow-y: hidden;">
    <div class="pageContainer" style="margin-bottom:200px; overflow-y: hidden; overflow-x: hidden;">
      <div class="mt-1" style="text-align:center"><h5 style="padding-top:3px;">Insurance Report</h5></div>
      <div class="mt-1" v-if="offices.length>0"><h6 style="padding-top:3px;"><strong>Offices:</strong> {{offices.map(item=>{return item.name}).join(", ")}}</h6></div>
      <div class="mt-1" v-if="clients.length>0"><h6 style="padding-top:3px;"><strong>Clients:</strong> {{clients.map(item=>{return item.name}).join(", ")}}</h6></div>
      <div class="mt-1" v-if="clientTypes.length>0"><h6 style="padding-top:3px;"><strong>Client Type:</strong> {{clientTypes.join(", ")}}</h6></div>
      <div class="mt-1" v-if="status.length>0"><h6 style="padding-top:3px;"><strong>Status:</strong> {{status.join(", ")}}</h6></div>
      <div class="mt-1" v-if="coverages.length>0"><h6 style="padding-top:3px;"><strong>Coverages:</strong> {{coverages.map(item=>{return item.name}).join(", ")}}</h6></div>
      <div class="mt-1" v-if="carriers.length>0"><h6 style="padding-top:3px;"><strong>Carriers:</strong> {{carriers.map(item=>{return item.name}).join(", ")}}</h6></div>
      <div class="mt-1" v-if="date[0]!=null && date[1]!=null"><h6 style="padding-top:3px;"><strong>Date:</strong> {{date[0] | moment("MM-DD-YYYY")}} - {{date[1] | moment("MM-DD-YYYY")}}</h6></div>

      <div class="row">
        <div class="col-12 bg-light border border-dark border-padding" style="margin-bottom:3px"> 

          <table class="table-sm table table-border m-0 pt-1">
            <thead>
              <tr>
                <th scope="col">Client Name</th>
                <th scope="col">Type</th>
                <th scope="col">Coverage</th>
                <th scope="col">Wholesaler</th>
                <th scope="col">Bill Type</th>
                <th scope="col">Carrier</th>
                <th scope="col">Policy #</th>
                <th scope="col">Finance Company</th>
                <th scope="col">Effective Date</th>
                <th scope="col">Expiration Date</th>
                <th scope="col">Total Premium</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-bind:key="item.id" v-for="item in tableData">
                <td class="bt" style="font-weight: bold; color:#0e5499">{{item.corporate.name}}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{item.insurance_type}}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{ item.coverage.name }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{ item.wholesaler.name }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{ item.bill_type }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{ item.carrier.name }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{ item.policy }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{ item.finance_company.name }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{ item.effective_date | moment("MM-DD-YYYY") }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{ item.expiration_date | moment("MM-DD-YYYY") }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">$ {{ new Intl.NumberFormat().format(item.total_premium) }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{item.status?'Active':'Inactive'}}</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import policy from "@/services/api/policy";

export default {
  data() {
    return {
      data:[],    
      tableData: [],
      offices: [],
      clients: [],
      clientTypes: [],
      status: [],
      coverages: [],
      carriers: [],
      date: [null,null],
    };
  },
  mounted() {
    this.offices=JSON.parse(this.$route.query.offices);
    this.clients=JSON.parse(this.$route.query.clients);
    this.clientTypes=JSON.parse(this.$route.query.clientTypes);   
    this.status=JSON.parse(this.$route.query.status);
    this.coverages=JSON.parse(this.$route.query.coverages);
    this.carriers=JSON.parse(this.$route.query.carriers);
    this.date=JSON.parse(this.$route.query.date);

     policy.get().then((response) => {
      this.data = Object.assign([], response);
      this.tableData = response;
      this.filterData()
    });

    this.download();
  },
  methods: {
    download() {
      this.fullscreenLoading = false;
      setTimeout(() => {
        this.$nextTick(() => {
          window.print();
        });
      }, 1000);
    },
    filterData(){

      let idOffices = this.offices.map(office=>office.id);
      let clients = this.clients.map(client=>client.id);
      let status = this.status;
      let clientTypes = this.clientTypes;
      let coverages = this.coverages.map(coverage=>coverage.id);
      let carriers = this.carriers.map(carrier=>carrier.id);


      this.tableData = Object.assign([], this.data);

      if (idOffices.length > 0) {
        this.tableData = this.tableData.filter(
          (item) =>
            idOffices.indexOf(item.officeId) != -1
        );
      }

      if (idOffices.length !== this.lengthOfficeFilter) {
        this.lengthOfficeFilter = idOffices.length;
      }
      if (idOffices.length == 0) {
        this.lengthOfficeFilter = 0;
        this.clientFilter = [];
        this.clients = [];
      }

      if (clients.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => clients.indexOf(item.corporate.id) != -1
        );
      }

      if (clientTypes.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => clientTypes.indexOf(item.insurance_type) != -1
        );
      }

      if (status.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => status.indexOf(item.status) != -1
        );
      }

      if (coverages.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => coverages.indexOf(item.coverage.id) != -1
        );
      }

      if (carriers.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => carriers.indexOf(item.carrier.id) != -1
        );
      }

      if(this.date[0]!=null && this.date[1]!=null){
        this.tableData=this.tableData.filter(item=>{
          var check = new Date(item.expiration_date);
          if(check > this.date[0] && check < this.date[1]){
            return true;
          }else{
            return false;
          }
        });
      }
      
    }
  }
};
</script>

<style lang="scss">

.pageContainer{
  margin-left: 30px;
  margin-right: 30px;
}

.pages{
  
}

table {
  border-collapse: separate; /* the default option */
  border-spacing: 0; /* remove border gaps */
}

th,td{
  font-size: 11px;
}


tr {
   line-height: 10px;
   min-height: 10px;
   height: 10px;
}


.bt {
  border-top: 1px dotted black !important;
}

.bb{
  border-bottom: 1px dotted black !important;
}

td{
  font-size: 9px;
}

.border-padding{
  padding-top: 2px;
  padding-bottom: 2px;
}


@page 
    {
        size: auto;   /* auto is the initial value */
        margin: 0mm;  /* this affects the margin in the printer settings */
        page-break-after: always !important;
        
    }



</style>